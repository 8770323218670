const initialState = {}

export default function MessageReducer(state = initialState, action) {
  switch (action.type) {
    case 'message/add': {
      return action.message
    }
    case 'message/remove': {
      return {}
    }
    default:
      return state
  }
}
