import React from "react";
import {useTranslation} from "react-i18next";
import {useTheme} from "./theme";

function ConfirmEnterContent(props) {

  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <img className="mb-2" src={theme.logo.src} alt={theme.logo.alt}/>
      <h1>{t("queue")}</h1>
      <p>
        {t("confirm_enter_info_1")}
        {t("confirm_enter_info_2")}
      </p>
    </>
  );
}

export { ConfirmEnterContent };
