import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import {EventSourcePolyfill} from 'event-source-polyfill'
import jwt_decode from "jwt-decode";
import {removeToken} from "./actions/TokenAction"
import { addRoot, removeRoot } from "./actions/RootAction";
import { addOldProgress, removeOldProgress } from "./actions/OldProgressAction";
import $ from "jquery";
import _debug from "../debug"
import ConfirmPassing from "./ConfirmPassing";
import qs from "qs";
import {Ripple} from "react-awesome-spinners";
import {useTranslation} from "react-i18next";
const theming = require('../themes/' + process.env.REACT_APP_THEME + '/theme');
const pa = require('../themes/' + process.env.REACT_APP_THEME + '/ProgressAnim');
const ProgressAnim = pa.ProgressAnim

function Position(props) {

  const { t } = useTranslation();
  const theme = theming.useTheme();

  const [queueState, setQueueState] = useState(null);
  const [passingUrl, setPassingUrl] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {

    var eventSources = {}
    var root = (props.root.value !== undefined) ? props.root.value : false;
    var oldProgress = (props.oldProgress.value !== undefined) ? props.oldProgress.value : false;

    // Clean store
    const cleanStore = () => {
      props.removeToken()
      props.removeRoot()
      props.removeOldProgress()
    }

    // Get progression of my token
    // id : my token's id in the queue
    // root : id of first token alive in the queue when my token was inserted in the queue (fixed reference point)
    // first1 : id of the current first token alive in the queue. This value only increases.
    // first2 : id of the current first token in rewaiting status in the queue. This value can decrease or increase.
    function getProgression(id, root, first1, first2) {
      for (var i=0; i < arguments.length; i++) {
        if(isNaN(arguments[i])) {
          _debug(arguments[i] + ' is NaN')
          return false;
        }
      }
      // Cast arguments
      id = Number(id)
      root = Number(root)
      first1 = Number(first1)
      first2 = Number(first2)
      if(root > first1 || id < root) {
        _debug('Incoherent parameter : {id: '+ id +', root: '+ root +', first1: '+ first1 +', first2: ' + first2 +'}')
        return false
      }

      // first1 <= id : Calculating progress for the "normal queue"
      // first > id : Calculating progress for the "rewaiting queue"
      // The inconsistency of the progression in the rewaiting queue is controlled by
      // the safety against go back progression.
      let head = (first1 > id) ? first2 : first1
      let initLenght = id - root
      let todo = id - head
      let progressPercent = (initLenght > 0) ? 1 - (todo / initLenght) : 1
      progressPercent = (progressPercent < 1) ? progressPercent : 0.99

      let debug = {
        id: id,
        root: root,
        first1: first1,
        first2: first2,
        initLenght: initLenght,
        todo: todo,
        progressPercent: progressPercent
      }
      _debug(debug)
      return progressPercent
    }

    function handleQueueStateChange(e) {
      let newQueueState = JSON.parse(e.data)
      setQueueState(newQueueState)

      // Set root if not exists
      if (!root) {
        props.addRoot({value: newQueueState.first1})
        root = newQueueState.first1
      }

      // Set oldProgress if not exists
      if (!oldProgress) {
        oldProgress = progress
        props.addOldProgress({value: progress})
      }

      let newProgress = getProgression(props.token.id, root, newQueueState.first1, newQueueState.first2)

      // Error in getProgression
      if(newProgress === false) {
        //// Clean store
        //cleanStore()
        //// Be sure unsubscribe mercure
        //mercureUnsubscribe()
        //// Redirect on error page
        //window.location.href = '/error'
        return false;
      }

      // Safety against go back progression
      let safeProgress = null
      if(newProgress < oldProgress)  {
        safeProgress = oldProgress
        _debug('Go back progression blocked! Maybe you are in the rewaiting queue.' +
          ' newProgress: '  + newProgress +
          ' oldProgress: '  + oldProgress +
          ' safeProgress: ' + safeProgress
        )
      } else {
        safeProgress = newProgress
      }

      // Set oldProgress
      oldProgress = safeProgress
      props.addOldProgress({value: safeProgress})

      // Set progress
      setProgress(safeProgress)
    }

    function cleanDestination(destination) {
      if (destination.indexOf('?') === -1 || !/token/i.test(destination)) {
        return destination;
      }
      let dest = destination.split('?');
      if(dest[1] === undefined) {
        return destination;
      } else {
        let uri = dest[0];
        let queryString = qs.parse(dest[1]);
        if (queryString.hasOwnProperty('token')) {
          delete queryString.token
        }
        return uri + '?' + qs.stringify(queryString, { encode: false })
      }
    }

    function getPassUrl(passingToken) {
      let passUrl = ''
      let queryString = qs.parse(window.location.search, {ignoreQueryPrefix: true});
      if (queryString.hasOwnProperty('destination')) {
        let destination = cleanDestination(queryString.destination)
        passUrl += destination;
      } else {
        // Redirect to / without destination param = error invalid URL
        window.location.href = '/'
        return false
      }
      passUrl += (passUrl.indexOf('?') === -1) ? '?' : '&'
      passUrl += 'token=' + passingToken
      return passUrl
    }

    function handleTokenChange(e) {
      _debug(e.data);
      let response = JSON.parse(e.data)
      if (response.passingToken !== undefined) {
        // Force 99% progress bar
        setProgress(0.99);
        // Clean store
        cleanStore()
        // Redirect to the site with 2 sec tempo
        let passUrl = getPassUrl(response.passingToken)
        setPassingUrl(passUrl)
        // be sure unsubscribe mercure
        mercureUnsubscribe()
      }
    }

    function handleQueueStateError(e) {
      _debug('Topic queue state error !')
    }

    function handleTokenError(e) {
      _debug('Topic token error !')
    }

    function mercureSubscribe(jwtMercure, urlsMercure, topics) {
      for (var i = 0; i < topics.length; i++) {
        let topic = topics[i]
        let urlMercure = ''
        if (topic.includes('queuestate')) {
          urlMercure = urlsMercure.queuestate
        }
        else if (topic.includes('token')) {
          urlMercure = urlsMercure.passingtoken;
        } else {
          continue
        }
        let urlSubscribe = urlMercure + '?topic=' + encodeURIComponent(topic)
        _debug('url subscribe : ' + urlSubscribe)
        eventSources[topic] = new EventSourcePolyfill(urlSubscribe, {
          headers: {
            'Authorization': 'Bearer ' + jwtMercure,
          },
          lastEventIdQueryParameterName: 'Last-Event-Id',
          heartbeatTimeout: '180000'
        });
        if (topic.includes('queuestate')) {
          eventSources[topic].onmessage = handleQueueStateChange
          eventSources[topic].onerror = handleQueueStateError
        }
        else if (topic.includes('token')) {
          eventSources[topic].onmessage = handleTokenChange
          eventSources[topic].onerror = handleTokenError
        }
      }
    }

    function mercureUnsubscribe() {
      _debug('Mercure : Unsubscribe !')
      Object.values(eventSources).forEach(eventSource => {
        eventSource.close()
      });
    }

    function tokenReady() {
      _debug('Token ' + props.token.uuid + ' Ready !')
      $.ajax({
        url: process.env.REACT_APP_API_URL + "/token/ready",
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({uuid: props.token.uuid}),
        success: function (data) {
        },
        error: function (xhr, textStatus, errorThrown) {
        }
      });
    }

    if (!$.isEmptyObject(props.token)) {
      let jwtMercure = props.token.jwtMercure
      let jwtMercureDecode = jwt_decode(jwtMercure)
      let topics = jwtMercureDecode.mercure.subscribe
      let urlsMercure = jwtMercureDecode.mercure.links
      _debug(jwtMercure)
      _debug(topics)
      _debug(urlsMercure)
      mercureSubscribe(jwtMercure, urlsMercure, topics)
      tokenReady()
    }

    return () => {
      mercureUnsubscribe()
    };

  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (queueState) {
    _debug({
      suspend: queueState.suspend,
      id: props.token.id,
      uuid: props.token.uuid,
      root: props.root.value,
      oldProgress: props.oldProgress.value
    });
  }

  if (passingUrl) {
    if (process.env.REACT_APP_COMFIRM_PASSING === 'yes') {
      return (
        <>
          <ConfirmPassing passingUrl={passingUrl}/>
        </>
      )
    }
    else {
      setTimeout(() => {
        window.location.href = passingUrl;
      }, 1000);
    }
  }

  if(queueState === null) {
    return (<div style={{textAlign: 'center'}}><Ripple color={theme.rippleColor} /></div>);
  } else if (parseInt(queueState.suspend) === 1) {
    return(
      <div className="progression_block">
        <div className="suspend">
          {t("suspend_queue_info_1")}<br/>
          {t("suspend_queue_info_2")}
        </div>
      </div>
    )
  } else {
    return (<ProgressAnim progress={progress}/>)
  }

}

const mapStateToProps = state => ({
  token: state.token,
  root: state.root,
  oldProgress: state.oldProgress
});

const mapDispatchToProps = dispatch => {
  return {
    removeToken: () => dispatch(removeToken()),
    addRoot: root => dispatch(addRoot(root)),
    removeRoot: () => dispatch(removeRoot()),
    addOldProgress: oldProgress => dispatch(addOldProgress(oldProgress)),
    removeOldProgress: () => dispatch(removeOldProgress()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Position);

