// import ConfirmEnter from "../ConfirmEnter";
import Dispenser from "../Dispenser";
import { connect } from "react-redux";
import {useTranslation} from "react-i18next";
import Detach from "../Detach";
import NoSleep from "../NoSleep";
import Message from '../Message';

const fc = require('../../themes/' + process.env.REACT_APP_THEME + '/FreeContent');
const FreeContent = fc.FreeContent

function MainPage(props) {

  const { t } = useTranslation();

  return (
    <>
      <div className="first_info_block">
        <div className="hourglass"></div>
        <h2 className="first_info">{t("please_wait")}</h2>
        <p className="first_info_sub">{t("waiting_queue_info")}</p>
      </div>
      <div className="progression_block">
        <Dispenser />
      </div>
      <div className="message_block">
        <Message />
      </div>
      <div className="main_info_block">
        <p className="subtitle">
          {t("wait_main_due_to_the_high_traffic")}
        </p>
        <p className={'bold-center'}>
          {t("wait_main_thank_you_for_waiting")}
        </p>
        <NoSleep/>
        <div className={t("some_tips").length ? 'tips' : 'd-none tips'}>
          {t("some_tips")}
          <ul>
            <li>{t("tips1")}</li>
            <li>{t("tips2")}</li>
            <li>{t("tips3")}</li>
            <li>{t("tips4")}</li>
            <li>{t("tips5")}</li>
            <li>{t("tips6")}</li>
          </ul>
        </div>
        <p className={t("thank_you_for_your_understanding").length ? '' : 'd-none'}>
          {t("thank_you_for_your_understanding")}
        </p>
        <Detach/>
      </div>
      <FreeContent/>
    </>
  );
}

const mapStateToProps = state => ({
  token: state.token,
  enterConfirm: state.enterConfirm,
});


export default connect(mapStateToProps)(MainPage);
