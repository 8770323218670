import React, { useState } from 'react';
import { connect } from 'react-redux';
import { EventSourcePolyfill } from 'event-source-polyfill';
import jwt_decode from 'jwt-decode';
import _debug from '../debug';
import Alert from 'react-bootstrap/Alert';
import { addMessage, removeMessage } from './actions/MessageAction';

function Message(props) {

  const [topicMessageSubscribed, setTopicMessageSubscribed] = useState(false);
  var eventSources = {};

  function handleMessageChange(e) {
    _debug(e.data);
    let newMessage = JSON.parse(e.data);
    props.removeMessage()
    props.addMessage({message : newMessage})
  }

  function handleMessageError(e) {
    _debug('Topic message error !');
  }

  function mercureSubscribe(jwtMercure, urlsMercure, topics) {
    for (var i = 0; i < topics.length; i++) {
      let topic = topics[i];
      if (!topic.includes('message')) {
        continue;
      }
      let urlMercure = urlsMercure.message;
      let urlSubscribe = urlMercure + '?topic=' + encodeURIComponent(topic);
      _debug('url subscribe : ' + urlSubscribe);
      eventSources[topic] = new EventSourcePolyfill(urlSubscribe, {
        headers: {
          'Authorization': 'Bearer ' + jwtMercure,
        },
        lastEventIdQueryParameterName: 'Last-Event-Id',
        heartbeatTimeout: '180000',
      });
      eventSources[topic].onmessage = handleMessageChange;
      eventSources[topic].onerror = handleMessageError;
    }
  }

  if (props.jwtMercure && !topicMessageSubscribed) {
    let jwtMercureDecode = jwt_decode(props.jwtMercure);
    let topics = jwtMercureDecode.mercure.subscribe;
    let urlsMercure = jwtMercureDecode.mercure.links;
    _debug(props.jwtMercure);
    _debug(topics);
    _debug(urlsMercure);
    mercureSubscribe(props.jwtMercure, urlsMercure, topics);
    setTopicMessageSubscribed(true);
  }

  if (props.message) {
    return (<Alert variant="info"><div dangerouslySetInnerHTML={{ __html: props.message }} /></Alert>);
  } else {
    return (<></>);
  }

}

const mapStateToProps = state => {
  return {
    jwtMercure: state.token.jwtMercure,
    message: state.message.message
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addMessage: message => dispatch(addMessage(message)),
    removeMessage: () => dispatch(removeMessage())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Message);

