import React, {useEffect} from "react";
import {connect} from "react-redux";
import { removeToken } from "./actions/TokenAction";
import { removeRoot } from "./actions/RootAction";
import { removeOldProgress } from "./actions/OldProgressAction";


function Reinit(props) {

  useEffect(() => {

    // Clean store
    const cleanStore = () => {
      props.removeToken()
      props.removeRoot()
      props.removeOldProgress()
      if (typeof sessionStorage === 'object') {
        sessionStorage.removeItem('localTokenChecked')
      }
    }

    cleanStore()

    setTimeout(function(){
      window.location.href = '/' + window.location.search
    }, 1000);

  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (<div></div>);

}

const mapStateToProps = state => ({
  token: state.token,
  root: state.root,
  oldProgress: state.oldProgress
})

const mapDispatchToProps = dispatch => {
  return {
    removeToken: () => dispatch(removeToken()),
    removeRoot: () => dispatch(removeRoot()),
    removeOldProgress: () => dispatch(removeOldProgress()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reinit)
