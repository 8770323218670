import React from "react";
import Button from "react-bootstrap/Button";
import {useTranslation} from "react-i18next";

function TimeoutConfirmPassingPage() {

  const { t } = useTranslation();

  return (
    <>
      <div className="timeout_block">
        <h1>{t("sorry")}</h1>
        <p>{t("sorry_you_didn_t_confirm_your_place_on_the_site_in_time")}</p>
        <p>
          <Button variant="primary" href={'/' + window.location.search}>
            {t("go_back_to_the_queue")}
          </Button>
        </p>
      </div>
    </>
  );
}

export default TimeoutConfirmPassingPage;
