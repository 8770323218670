import React from "react";
import Error from "../Error";

function ErrorPage() {
  return (
    <>
      <Error />
    </>
  );
}

export default ErrorPage;
