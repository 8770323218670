const initialState = {}

export default function TokenReducer(state = initialState, action) {
  switch (action.type) {
    case 'token/add': {
      return action.token
    }
    case 'token/remove': {
      return {}
    }
    default:
      return state
  }
}
