import React, {useState, useEffect, useContext} from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {useTranslation} from "react-i18next";
import { DetachModalManagerContext } from "./DetachModalManager";

function ConfirmPassing(props) {

  const { t } = useTranslation();

  const detachModalManager = useContext(DetachModalManagerContext);

  const [countdown, setCountdown] = useState(process.env.REACT_APP_COMFIRM_PASSING_TIMEOUT);

  useEffect(() => {

    const down = () => {
      if(countdown <= 0) {
        window.location.href= '/timeout' + window.location.search
      } else {
        setCountdown(countdown - 1000)
      }
    }

    // Force close detach modal
    if(detachModalManager.isOpen) {
      detachModalManager.handleCloseModal()
    }

    let timerId = setInterval(down, 1000);
    return () => clearInterval(timerId);

  });

  const handleConfirmOk = () => {
    window.location.href=props.passingUrl
  };
  const handleClose = () => {
    return false
  }

  const msToTime = (duration) => {
    let  seconds = Math.floor((duration / 1000) % 60)
    let  minutes = Math.floor((duration / (1000 * 60)) % 60)
    //let  hours = Math.floor((duration / (1000 * 60 * 60)) % 24)
    //hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;
    return minutes + ":" + seconds;
  }

  return (
    <>
      <Modal
        show={true}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="confirm-passing"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("it_s_your_turn")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t("please_confirm_your_entry_on_the_site")} {t("click_on_access_button")}
          </p>
          <p className={'alert-inactif'}>
            {t("your_place_expires_in", { countdown: msToTime(countdown) })}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div class="btn-acces-vente">
            <div className="btn-timer"><span>{ msToTime(countdown) }</span></div>
            <Button variant="primary" onClick={handleConfirmOk}>
              {t("let_s_go")}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );

}

export default ConfirmPassing;
