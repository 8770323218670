import React, {useContext} from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DetachForm from "./DetachForm";
import {useTranslation} from "react-i18next";
import { DetachModalManagerContext } from "./DetachModalManager";
import {connect} from "react-redux";
import $ from "jquery";
const dmc = require('../themes/' + process.env.REACT_APP_THEME + '/DetachModalContent');
const DetachModalContent = dmc.DetachModalContent

function DetachModal(props) {

  const { t } = useTranslation();

  const modalManager = useContext(DetachModalManagerContext);

  const handleClose = () => modalManager.handleCloseModal();

  const handleShow = () => modalManager.handleOpenModal();

  const isLoading = $.isEmptyObject(props.token)

  return (
    <>
      <Button variant="primary"
              onClick={!isLoading ? handleShow : null}
              disabled={isLoading}>
        <span>{isLoading ? t("loading") : t("let_me_know_when_it_s_my_turn")}</span>
      </Button>
      <Modal
        show={modalManager.isOpen}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="detach-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("let_me_know_when_it_s_my_turn")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DetachModalContent/>
          <DetachForm/>
        </Modal.Body>
      </Modal>
    </>
  );


}

const mapStateToProps = state => ({
  token: state.token,
});

export default connect(
  mapStateToProps,
)(DetachModal);
