import React, { useEffect } from 'react';
import { connect } from "react-redux";
import $ from 'jquery';
import _debug from "../debug"

function KeepAlive(props) {

  useEffect(() => {

    const keepAlive = () => {
      _debug('keep alive !')
      if (!$.isEmptyObject(props.token)) {
        _debug(props.token.uuid)
        $.ajax({
          url: process.env.REACT_APP_API_URL + "/token/keepalive",
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          data: JSON.stringify({uuid: props.token.uuid}),
          success: function (data) {
          },
          error: function (xhr, textStatus, errorThrown) {
          }
        });
      }
    }

    let timerId = window.setInterval(keepAlive, 120000);
    return () => clearInterval(timerId);

  }, [])  // eslint-disable-line react-hooks/exhaustive-deps

  return (<></>)
}

const mapStateToProps = state => ({
  token: state.token,
});

export default connect(
  mapStateToProps
)(KeepAlive);
