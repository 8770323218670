
export const confirmEnter = () => {
  return {
    type: 'enter/confirm',
    confirm: true,
  };
};

export const resetEnter = () => {
  return {
    type: 'enter/reset',
    confirm: false,
  };
};
