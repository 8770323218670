const initialState = {}

export default function OldProgressReducer(state = initialState, action) {
  switch (action.type) {
    case 'oldProgress/add': {
      return action.oldProgress
    }
    case 'oldProgress/remove': {
      return {}
    }
    default:
      return state
  }
}
