const initialState = false

export default function EnterReducer(state = initialState, action) {
  switch (action.type) {
    case 'enter/confirm': {
      return true
    }
    case 'enter/reset': {
      return false
    }
    default:
      return state
  }
}
