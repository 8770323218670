
export const addMessage = message => {
  message["__persisted_at"] = (new Date()).toJSON()
  return {
    type: 'message/add',
    message,
  };
};

export const removeMessage = () => {
  return {
    type: 'message/remove',
  };
};
