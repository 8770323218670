import React, {useState} from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {connect} from "react-redux";
import { confirmEnter, resetEnter } from "./actions/EnterAction";
import {useTranslation} from "react-i18next";
import ConfirmEnterGdpr from "./ConfirmEnterGdpr";
import ConfirmEnterCaptcha from "./ConfirmEnterCaptcha";
const cec = require('../themes/' + process.env.REACT_APP_THEME + '/ConfirmEnterContent');
const ConfirmEnterContent = cec.ConfirmEnterContent


function ConfirmEnter(props) {

  const [gdprAccepted, setGdprAccepted] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const handleClose = () => false;
  const handleAccept = () => props.confirmEnter();

  const changeGdpr = () => {
    setGdprAccepted(!gdprAccepted)
  }

  const onVerify = recaptchaResponse => {
    setCaptchaVerified(true)
  }

  const canEnter = () => {
    if(process.env.REACT_APP_GDPR === 'yes' && !gdprAccepted) {
      return false
    }
    if(process.env.REACT_APP_CAPTCHA === 'yes' && !captchaVerified) {
      return false
    }
    return true;
  }

  const { t } = useTranslation();

  return (
    <Modal
      show={!props.enterConfirm}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="confirm-enter"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("welcome")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ConfirmEnterContent/>
        <ConfirmEnterGdpr changeGdpr={changeGdpr} />
        <ConfirmEnterCaptcha onVerify={onVerify} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleAccept} disabled={!canEnter()}>
          {t("enter")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = state => ({
  token: state.token,
  enterConfirm: state.enterConfirm,
});

const mapDispatchToProps = dispatch => {
  return {
    confirmEnter: () => dispatch(confirmEnter()),
    resetEnter: () => dispatch(resetEnter()),
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmEnter);

