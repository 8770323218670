import React, {useState} from "react";
import {Formik} from 'formik';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import * as Yup from 'yup';
import $ from "jquery";
import _debug from "../debug"
import {removeToken} from "./actions/TokenAction";
import {removeRoot} from "./actions/RootAction";
import {removeOldProgress} from "./actions/OldProgressAction";
import {connect} from "react-redux";
import Alert from 'react-bootstrap/Alert';
import {useTranslation} from "react-i18next";
import Reaptcha from "reaptcha";
import qs from "qs";

function DetachForm(props) {

  const {t, i18n} = useTranslation();

  const [detached, setDetached] = useState(false);
  const [apiError, setApiError] = useState({"error": false, "msg": ""});
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const onVerify = recaptchaResponse => {
    setCaptchaVerified(true)
  }

  const canPost = () => {
   if(process.env.REACT_APP_CAPTCHA === 'yes' && !captchaVerified) {
      return false
    }
    return true;
  }

  const tokenDetach = (values, {setSubmitting}) => {
    _debug('tokenDetach !')

    // If param 'destination' exists
    let destination = '';
    let queryString = qs.parse(window.location.search, {ignoreQueryPrefix: true});
    if (queryString.hasOwnProperty('destination')) {
      destination = encodeURIComponent(queryString.destination)
    }

    // Get root and oldProgress value
    let root = (props.root.value !== undefined) ? props.root.value : 0;
    let oldProgress = (props.oldProgress.value !== undefined) ? props.oldProgress.value : 0;

    $.ajax({
      url: process.env.REACT_APP_API_URL + "/token/detach",
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        uuid: props.token.uuid,
        mail: values.email,
        lang: i18n.language,
        theme: process.env.REACT_APP_THEME,
        destination: destination,
        root: root,
        oldProgress: oldProgress
      }),
      success: function (data) {
        // Clean store
        props.removeToken()
        props.removeRoot()
        props.removeOldProgress()
        // Set detach state for redirect to detached page
        setDetached(true)
      },
      error: function (xhr, textStatus, errorThrown) {
        let msg = t('sorry_an_error_occurs_please_try_again_later')
        if( xhr.responseJSON.detail === 'Token already detached') {
          msg = t('error_token_already_detached')
        }
        setApiError({"error": true, "msg": msg})
      }
    });
  }

  if (detached) {
    window.location.href = '/detached'
    return (<></>);
  }
  else {
    if (apiError.error) {
      return (<Alert
        variant='danger'>{apiError.msg}</Alert>);
    }
    else {
      return (
        <div>
          <Formik
            initialValues={{email: ''}}
            validationSchema={Yup.object({
              email: Yup.string().email(t("invalid_email_address")).required(t("required_email_address")),
            })}
            onSubmit={tokenDetach}
          >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
              }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formEmail">
                  <Form.Label>{t("email")} :</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    placeholder={t("enter_email")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    className={touched.email && errors.email ? "error" : null}
                  />
                  {touched.email && errors.email ?
                    (<Form.Text className="text-muted-error">{errors.email}</Form.Text>)
                    :
                    (<Form.Text className="text-muted">{t("we_ll_never_share_your_email")}</Form.Text>)}
                </Form.Group>
                {(process.env.REACT_APP_CAPTCHA === 'yes') ?
                  (<Form.Group><Reaptcha sitekey={process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY} onVerify={onVerify}/></Form.Group>)
                : null }
                <Button variant="primary" type="submit" disabled={!canPost()}>
                  {t("submit")}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      );
    }
  }

}

const mapStateToProps = state => ({
  token: state.token,
  root: state.root,
  oldProgress: state.oldProgress,
});

const mapDispatchToProps = dispatch => {
  return {
    removeToken: () => dispatch(removeToken()),
    removeRoot: () => dispatch(removeRoot()),
    removeOldProgress: () => dispatch(removeOldProgress()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetachForm);
