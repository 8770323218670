import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./components/store/ConfigureStore";
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import './i18nextConf';
import App from './components/App';
import DetachModalManager from './components/DetachModalManager'
const theming = require('./themes/' + process.env.REACT_APP_THEME + '/theme');
const Theme = theming.Theme

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <React.Suspense fallback={<></>}>
            <Theme>
              <DetachModalManager>
                <App />
              </DetachModalManager>
            </Theme>
          </React.Suspense>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
