import React, {useState, useEffect} from 'react';
import ProgressBar from "progressbar.js"
const theming = require('./theme');

function ProgressAnim(props) {

  const theme = theming.useTheme();

  const [progressBarObject, setProgressBarObject] = useState(null);

  useEffect(() => {
    if (progressBarObject === null) {
      // progressbar.js@1.0.0 version is used
      // Docs: http://progressbarjs.readthedocs.org/en/1.0.0/

      var themeColor = theme.themeColor;

      var pb = new ProgressBar.Line('#progress', {
        strokeWidth: 4,
        easing: 'easeInOut',
        duration: 1400,
        color: themeColor,
        trailColor: '#eee',
        trailWidth: 1,
        svgStyle: {width: '100%', height: '100%'},
        text: {
          style: {
            // Text color.
            // Default: same as stroke color (options.color)
            color: '#999',
            position: 'absolute',
            right: '0',
            top: '30px',
            padding: 0,
            margin: 0,
            transform: null
          },
          autoStyleContainer: false
        },
        from: {color: themeColor},
        to: {color: '#ED6A5A'},
        step: (state, bar) => {
          bar.setText(Math.round(bar.value() * 100) + ' %');
        }
      });

      pb.animate(1.0);

      setProgressBarObject(pb)
    } else {
      progressBarObject.animate(props.progress)
    }
  });

  return (<><div id="progress"/></>)

}

export {ProgressAnim};
