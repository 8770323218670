import Reaptcha from "reaptcha";

function ConfirmEnterCaptcha(props) {
  if (process.env.REACT_APP_CAPTCHA === 'yes') {
    return (
      <p>
        <Reaptcha sitekey={process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY}
                  onVerify={props.onVerify}/>
      </p>
    )
  }
  else {
    return (<></>)
  }
}

export default ConfirmEnterCaptcha;

