
export const addToken = token => {
  token["__persisted_at"] = (new Date()).toJSON()
  return {
    type: 'token/add',
    token,
  };
};

export const removeToken = () => {
  return {
    type: 'token/remove',
  };
};
