import React from "react";
import {useTranslation} from "react-i18next";
import DetachModal from "./DetachModal";

function Detach(props) {

  const { t } = useTranslation();

  if (process.env.REACT_APP_DETACH === 'yes') {
    return (
      <div className="description-alert">
        <p>
          {t("wait_main_if_you_wish_alerted")}
        </p>
        <DetachModal />
      </div>
    )
  }
  else {
    return(<></>)
  }

}

export default Detach;
