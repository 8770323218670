import { createStore, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import expireReducer from "redux-persist-expire";
import localForage from "localforage";

import TokenReducer from "../reducers/TokenReducer";
import RootReducer from "../reducers/RootReducer";
import OldProgressReducer from "../reducers/OldProgressReducer";
import EnterReducer from "../reducers/EnterReducer";
import MessageReducer from '../reducers/MessageReducer';

const persistConfig = {
  key: 'root',
  storage: localForage,
  transforms: [
    expireReducer('token', {expireSeconds: 43200, expiredState: {}}),
    expireReducer('root', {expireSeconds: 43200, expiredState: {}}),
    expireReducer('oldProgress', {expireSeconds: 43200, expiredState: {}}),
    expireReducer('message', {expireSeconds: 43200, expiredState: {}})
  ],
};

const rootReducer = combineReducers({
  token: TokenReducer,
  root: RootReducer,
  oldProgress: OldProgressReducer,
  enterConfirm: EnterReducer,
  message: MessageReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer);
export const persistor = persistStore(store);
