import * as React from 'react'

export const DetachModalManagerContext = React.createContext({
  handleOpenModal: () => {},
  handleCloseModal: () => {},
  isOpen: false
})

const DetachModalManager = ({ children }) => {
  const [modalState, handleChangeState] = React.useState({
    isOpen: false
  })

  const handleOpenModal = (data = {}) => {
    handleChangeState({ isOpen: true })
  }

  const handleCloseModal = () => {
    handleChangeState({ isOpen: false })
  }

  return (
    <DetachModalManagerContext.Provider
      value={{
        handleOpenModal,
        handleCloseModal,
        isOpen: modalState.isOpen
      }}
    >
      {children}
    </DetachModalManagerContext.Provider>
  )
}

export default DetachModalManager
