import React from "react";
import {useTranslation} from "react-i18next";

function DetachModalContent(props) {

  const { t } = useTranslation();
  //const theme = useTheme();

  return (
    <>
      <p>
        {t("enter_your_email_to_get_out_and_still_keep_your_place")}
      </p>
      <div className="detach-sifflet"></div>
    </>
  );
}

export { DetachModalContent };
