
export const addRoot = root => {
  root["__persisted_at"] = (new Date()).toJSON()
  return {
    type: 'root/add',
    root,
  };
};

export const removeRoot = () => {
  return {
    type: 'root/remove',
  };
};
