import {useTranslation} from "react-i18next";

function DetachedPage() {

  const { t } = useTranslation();

  return (
    <>
      <div className="detached_block">
        <h1>{t("you_are_detached_from_the_queue")}</h1>
        <p>
          {t("please_check_your_emails_to_rejoin_the_queue")}
        </p>
        <p>
          {t("detach_explain_info")}
        </p>
        <p>
          {t("you_can_close_this_page")}
        </p>
        <p>
          {t("see_you_soon")}
        </p>
      </div>
    </>

  );
}

export default DetachedPage;
