import qs from "qs";

function _debug(msg) {
  let debug = qs.parse(window.location.search, {ignoreQueryPrefix: true})
    .hasOwnProperty('debug');
  if(debug) {
    console.log(msg);
  }
}

export default _debug
