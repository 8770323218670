const initialState = {}

export default function RootReducer(state = initialState, action) {
  switch (action.type) {
    case 'root/add': {
      return action.root
    }
    case 'root/remove': {
      return {}
    }
    default:
      return state
  }
}
