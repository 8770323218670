import React from "react";
import {useTheme} from "./theme";

function Header(props) {

  const theme = useTheme();

  return (
    <>
      <header>
        <div className="logo-wrapper">
          <img src={theme.logo.src} alt={theme.logo.alt}/>
        </div>
      </header>
    </>
  );
}

export { Header };






