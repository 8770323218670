import React from 'react'
import Main from './Main'
import ConfirmEnter from "./ConfirmEnter";
import {confirmEnter, resetEnter} from "./actions/EnterAction";
import {connect} from "react-redux";
import qs from "qs";
import {useTranslation} from "react-i18next";
import Alert from "react-bootstrap/Alert";
import _debug from "../debug";
const head = require('../themes/' + process.env.REACT_APP_THEME + '/Header');
const Header = head.Header
const foot = require('../themes/' + process.env.REACT_APP_THEME + '/Footer');
const Footer = foot.Footer

function App(props) {

  const { t } = useTranslation();

  function isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  if(window.location.pathname === '/') {
    let queryString = qs.parse(window.location.search, {ignoreQueryPrefix: true});
    if (!queryString.hasOwnProperty('destination') || !isValidHttpUrl(queryString.destination)) {
      _debug('Destination invalid : ' + window.location.href)
      return (
        <Alert variant="danger">
          <Alert.Heading>{t("error_invalid_url")}</Alert.Heading>
          <div>{window.location.href}</div>
        </Alert>
      )
    } else {
      _debug('Destination : ' + queryString.destination)
    }
  }

  if(process.env.REACT_APP_COMFIRM_ENTER === 'yes' && !props.enterConfirm) {
    return (
      <ConfirmEnter />
    );
  } else {
    return (
      <div className="global_waiting_page">
        <Header/>
        <div className="main_block">
          <Main/>
        </div>
        <Footer/>
      </div>
    )
  }

}

const mapStateToProps = state => ({
  enterConfirm: state.enterConfirm,
});

const mapDispatchToProps = dispatch => {
  return {
    confirmEnter: () => dispatch(confirmEnter()),
    resetEnter: () => dispatch(resetEnter()),
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
