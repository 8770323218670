// import ConfirmEnter from "../ConfirmEnter";
import { connect } from "react-redux";
import Reattacher from "../Reattacher";

function ReattachPage(props) {

  return (
    <>
      <Reattacher />
    </>

  );
}

const mapStateToProps = state => ({
  enterConfirm: state.enterConfirm,
});


export default connect(mapStateToProps)(ReattachPage);
