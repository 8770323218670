import React, { useEffect } from 'react';
import { connect } from "react-redux";
import $ from 'jquery';
import _debug from "../debug"
import {removeToken} from "./actions/TokenAction";
import { removeRoot } from "./actions/RootAction";
import { removeOldProgress } from "./actions/OldProgressAction";

function HealthCheck(props) {

  useEffect(() => {

    // Clean store
    const cleanStore = () => {
      props.removeToken()
      props.removeRoot()
      props.removeOldProgress()
      window.location.href= '/error' + window.location.search
    }

    const healthCheck = () => {
      if (!$.isEmptyObject(props.token)) {
        const uuid = props.token.uuid
        _debug('Token ' + uuid + ' health check !')
        $.ajax({
          url: process.env.REACT_APP_API_URL + "/token/" + uuid,
          type: 'GET',
          headers: {
            'Accept': 'application/json',
          },
          success: function (data) {
            // Clean local token if token status is undefined or -1 (Abandoned) or -2 (Killed)
            // Nothing is done if the token is in status -3 (Used) because this is not necessarily
            // an error: If the Healcheck is performed when the user is authorized to pass on the
            // site but has not yet validated the confirmation of entry on the site, his waiting token
            // is in status -3 but he has a valid passing token. He should not be redirected to the error page.
            if(data.status === undefined || (data.status < 0 && data.status !== -3) ) {
              _debug('Token ' + uuid + ' health check FAILED !')
              _debug('-> API return status ' + data.status + ' for token ' + uuid)
              _debug('-> Reset all stored values')
              cleanStore()
            }
          },
          error: function (xhr, textStatus, errorThrown) {
            _debug('Token ' + uuid + ' health check FAILED !')
            _debug('-> API return : ' + textStatus + ' ' + xhr.status + ' ' + errorThrown)
            if(parseInt(xhr.status) === 400) {
              _debug('-> Reset all stored values')
              cleanStore()
            }
          }
        });
      }
    }

    let timerId = window.setInterval(healthCheck, 300000);
    return () => clearInterval(timerId);

  }, [])  // eslint-disable-line react-hooks/exhaustive-deps

  return (<></>)
}

const mapStateToProps = state => ({
  token: state.token,
});

const mapDispatchToProps = dispatch => {
  return {
    removeToken: () => dispatch(removeToken()),
    removeRoot: () => dispatch(removeRoot()),
    removeOldProgress: () => dispatch(removeOldProgress()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthCheck);
