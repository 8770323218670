import React from "react";
import Reinit from "../Reinit";

function ReinitPage() {
  return (
    <>
      <Reinit />
    </>
  );
}

export default ReinitPage;
