import React from "react";
import Button from "react-bootstrap/Button";
import {useTranslation} from "react-i18next";

function Error(props) {

  const { t } = useTranslation();

  return (
    <>
      <div className="error_block">
        <h1>{t("oops")}</h1>
        {/* <p>{t("sorry_an_error_occurs")}</p> */}
        <p className="error_reasons">
          {t("error_reasons_may_include")}
          <ul>
            <li>{t("error_reason_1")}</li>
            <li>{t("error_reason_2")}</li>
            <li>{t("error_reason_3")}</li>
          </ul>
        </p>
        <p className="message">{props.message}</p>
        <p className="return_btn">
          <Button variant="primary" href={'/' + window.location.search}>
            {t("go_back_to_the_queue")}
          </Button>
        </p>
      </div>
    </>
  );
}

export default Error;
