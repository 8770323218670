import * as React from 'react'
import NoSleepJs from 'nosleep.js'
import Form from "react-bootstrap/Form"
import {isMobile} from "react-device-detect"
import {useTranslation} from "react-i18next"

class ReactNoSleep extends React.Component {

  constructor(props) {
    super(props);
    this._noSleep = new NoSleepJs();
    this.state = {
      isOn: false
    };
  }

  render() {
    return this.props.children({
      isOn: this.state.isOn,
      enable: this.handleEnable,
      disable: this.handleDisable
    });
  }

  handleEnable = () => {
    this._noSleep.enable();
    this.setState({
      isOn: true
    });
  };

  handleDisable = () => {
    this._noSleep.disable();
    this.setState({
      isOn: false
    });
  };

}

function NoSleep(props) {

  const { t } = useTranslation();

  if (!isMobile) {
    return (<></>)
  }

  return (
    <p>
      <p>{t("no_sleep_mode_info")}</p>
      <p>
        <ReactNoSleep>
          {({isOn, enable, disable}) => (
            <Form className="form-no-sleep">
              <Form.Switch
                onChange={isOn ? disable : enable}
                id="no-sleep-switch"
                label={isOn ? t("no_sleep_mode_on") : t("no_sleep_mode_off")}
                checked={isOn ? true : false}
              />
            </Form>
          )}
        </ReactNoSleep>
      </p>
    </p>
  );

}
export default NoSleep
