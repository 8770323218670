import {useTranslation} from "react-i18next";
import Form from 'react-bootstrap/Form'

function ConfirmEnterGdpr(props) {

  const {t} = useTranslation();

  if (process.env.REACT_APP_GDPR === 'yes') {
    return (
      <p>
        <Form>
          <Form.Check type="checkbox" checked={props.gdprAccepted}
                      onChange={props.changeGdpr} label={t("accept_gdpr")}/>
        </Form>
      </p>
    )
  }
  else {
    return (<></>)
  }
}

export default ConfirmEnterGdpr;

