import React from 'react';
import { Switch, Route } from 'react-router-dom';
import MainPage from './pages/MainPage';
import DetachedPage from './pages/DetachedPage';
import TimeoutConfirmPassingPage from "./pages/TimeoutConfirmPassingPage";
import ReattachPage from './pages/ReattachPage';
import ErrorPage from './pages/ErrorPage';
import ReinitPage from './pages/ReinitPage';

const Main = () => {
  return (
    <Switch> {/* The Switch decides which component to show based on the current URL.*/}
      <Route exact path='/' component={MainPage}></Route>
      <Route exact path='/detached' component={DetachedPage}></Route>
      <Route exact path='/timeout' component={TimeoutConfirmPassingPage}></Route>
      <Route exact path='/reattach' component={ReattachPage}></Route>
      <Route exact path='/error' component={ErrorPage}></Route>
      <Route exact path='/reinit' component={ReinitPage}></Route>
    </Switch>
  );
}

export default Main;
