import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import {addToken, removeToken} from "./actions/TokenAction";
import {addRoot, removeRoot} from "./actions/RootAction";
import {addOldProgress, removeOldProgress} from "./actions/OldProgressAction";
import $ from 'jquery';
import _debug from "../debug"
import {Ripple} from "react-awesome-spinners";
import qs from "qs";
import Error from "./Error";
const theming = require('../themes/' + process.env.REACT_APP_THEME + '/theme');

function Reattacher(props) {

  const theme = theming.useTheme();

  const [error, setError] = useState(null);

  useEffect(() => {

    function reattach() {
      _debug('Reattach !');
      let queryString = qs.parse(window.location.search, {ignoreQueryPrefix: true});
      if (!queryString.hasOwnProperty('key') || queryString.key.length === "") {
        setError({message: "Bad request"})
        return
      }
      let reattachKey = queryString.key
      $.ajax({
        url: process.env.REACT_APP_API_URL + "/token/reattach",
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({reattachKey: reattachKey}),
        success: function (data) {
          _debug(data)
          let destination = data.destination
          let root = data.root
          let oldProgress = data.oldProgress
          delete data.destination
          delete data.root
          delete data.oldProgress
          let token = data
          _debug(destination)
          _debug(root)
          _debug(oldProgress)
          _debug(token)
          // Clean store
          props.removeToken()
          props.removeRoot()
          props.removeOldProgress()
          // Set store
          props.addToken(token)
          props.addRoot({value: root})
          props.addOldProgress({value: oldProgress})
          // Reinject destination param in URL
          if(destination !== undefined && destination !== '') {
            destination = '?destination='+destination
          }
          // Redirect to MainPage
          setTimeout(() => {
            window.location.href = '/' + destination;
          }, 1000);
        },
        error: function (xhr, textStatus, errorThrown) {
          _debug(xhr.status)
          _debug(textStatus)
          _debug(errorThrown)
          setError({message: errorThrown});
        }
      })
    }

    reattach()

  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (error) {
    return (
      <>
        <Error message={error.message} />
      </>
    )
  }
  else {
    return (
      <div className="ripple_block">
          <Ripple color={theme.rippleColor} />
      </div>);
  }

}

const mapStateToProps = state => ({
  token: state.token,
  root: state.root,
  oldProgress: state.oldProgress,
});

const mapDispatchToProps = dispatch => {
  return {
    addToken: token => dispatch(addToken(token)),
    removeToken: () => dispatch(removeToken()),
    addRoot: root => dispatch(addRoot(root)),
    removeRoot: () => dispatch(removeRoot()),
    addOldProgress: oldProgress => dispatch(addOldProgress(oldProgress)),
    removeOldProgress: () => dispatch(removeOldProgress()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reattacher);
