
export const addOldProgress = oldProgress => {
  oldProgress["__persisted_at"] = (new Date()).toJSON()
  return {
    type: 'oldProgress/add',
    oldProgress,
  };
};

export const removeOldProgress = () => {
  return {
    type: 'oldProgress/remove',
  };
};
